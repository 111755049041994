import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons"

const PricingItem = ({ title, description, className, price }) => (
  <div className="border rounded-lg shadow-md bg-white flex flex-col">
    <header className="m-4">
      <h1 className={`h1 text-center relative truncate ${className}`}>
        <span>{title}</span>
        <div
          className="top-0 bottom-0 left-0 right-0 absolute pointer-events-none mix-blend-overlay"
          style={{
            backgroundImage: `linear-gradient(45deg, black, rgba(255, 255, 255, 0.5))`
          }}
        ></div>
      </h1>
      <p className="text-center font-bold">{description}</p>
    </header>
    <main className="flex-grow">
      <div className="p-4 border-t">
        <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" /> Webdesign
        <p className="text-gray-700 mt-2">
          Een professioneel ontwerp dat goed aansluit bij uw bedrijf. Werkt goed
          op mobiel en desktop.
        </p>
      </div>
      <div className="p-4 border-t">
        <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" /> Webhosting
        <p className="text-gray-700 mt-2">
          Wij zorgen dat uw site online komt, en blijft. Daarnaast zorgen we dat
          de site razendsnel laadt.
        </p>{" "}
      </div>
      <div className="p-4 border-t border-b">
        <FontAwesomeIcon icon={faPlus} size="sm" className="mr-2" /> Support
        <p className="text-gray-700 mt-2">
          Wij staan altijd voor u klaar wanneer u aanpassingen wilt maken of
          andere vragen heeft.
        </p>
      </div>
    </main>
    <footer className="m-4">
      <div className="mt-4">
        <button className="button button--ghost w-full group">
          <span className="text-xl font-bold">€ {price}</span> p.m.
          <p className="text-gray-700 group-hover:text-white">
            1-jarig abbonement
          </p>
          <p className="text-xs text-gray-500 group-hover:text-purple-200">
            Vrijgesteld van btw
          </p>
        </button>
      </div>
    </footer>
  </div>
)

export default function Tarieven() {
  return (
    <Layout>
      <Seo title="Tarieven" />
      <div className="container py-8">
        <h1 className="h1">
          Tarieven voor <em className="highlight">professioneel webdesign</em>{" "}
          &amp; webhosting
        </h1>
        <p className="paragraph">
          Betrouwbare service voor een betaalbare prijs. Kies het pakket dat het
          beste bij u past.{" "}
          <Link to="/contact" className="link">
            Niet helemaal duidelijk? Stel een vraag
          </Link>
          .
        </p>
      </div>
      <div className="container px-0 sm:px-4 md:px-8">
        <section className="border-t border-b sm:border-l sm:border-r bg-gray-100 px-0 py-6 md:px-6 md:py-8 rounded-sm">
          <div className="row md:-mt-12">
            <div className="column w-full md:w-1/3 p-8 py-4 md:px-4 md:pt-12">
              <PricingItem
                title="Basis"
                description="Website met 1 pagina"
                className="text-green-500"
                price="24"
              />
            </div>
            <div className="column w-full md:w-1/3 p-8 py-4 md:px-4 md:pt-12">
              <PricingItem
                title="Plus"
                description="Met meerdere pagina's"
                className="text-blue-500"
                price="36"
                isPrimary
              />
            </div>
            <div className="column w-full md:w-1/3 p-8 py-4 md:px-4 md:pt-12">
              <PricingItem
                title="Deluxe"
                description="Met functionaliteit op maat"
                className="text-purple-500"
                price="48"
              />
            </div>
          </div>
        </section>
      </div>
      <div className="container py-8">
        <section className="border-t border-b sm:border-l sm:border-r bg-gray-100 px-0 py-6 md:px-6 md:py-8 rounded-sm">
          <h2 className="h2">Veel gestelde vragen</h2>
          <h3 className="h3 mt-4">Wat is de looptijd van het abonnement?</h3>
          <p className="paragraph">
            Het abonnement heeft een looptijd van minstens 1 jaar. Daarna is het
            maandelijks opzegbaar.
          </p>
          <h3 className="h3 mt-4">
            Wat gebeurt er met de site wanneer ik het abonnement opzeg?
          </h3>
          <p className="paragraph">
            Mocht u niet tevreden zijn en het abonnement willen opzeggen, dan
            heeft u de mogelijkheid om de website over te nemen. U zult een
            persoon met technische kennis nodig hebben om de site weer online te
            zetten.
          </p>
        </section>

        <footer className="bg-gray-300 p-8 mt-4 md:mt-8 rounded-sm">
          <div className="flex flex-wrap items-center -m-2">
            <main className="flex-grow p-2">
              <h2 className="h2 mb-0">Tijd voor aan kennismaking?</h2>
              <p className="text">
                Wij gaan op professionele wijze te werk. Neem contact op voor
                een vrijblijvende offerte.
              </p>
            </main>
            <aside className="p-2">
              <Link to="/contact" className="button">
                Neem contact op
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </Link>
            </aside>
          </div>
        </footer>
      </div>
    </Layout>
  )
}
